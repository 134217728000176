import React, { CSSProperties } from "react";

import SectionContainer from "../../shared-components/SectionContainer";
import HeaderText from "../../shared-components/HeaderText";
import { Button } from "../../shared-components/Button";
import GridContainer from "../../shared-components/GridContainer";
import { useInView } from "framer-motion";
const AboutUs = () => {
  const bgStyle: CSSProperties = {
    backgroundImage: "url(/assets/images/africa-bg.png)",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };
  return (
    <div className="w-full flex flex-col md:gap-32 gap-16">
      <SectionContainer>
        <div className="">
          <div className="grid md:grid-cols-12  ">
            <div className="flex flex-col gap-8 col-span-8">
              <div className="text-neutral-70 text-[32px] leading-[40px] ">
                Mission
              </div>

              <HeaderText>
                Une équipe internationale pour relever un défi mondial
              </HeaderText>
            </div>
          </div>
          <div className="grid md:grid-cols-12 md:my-16 my-12 ">
            <div className="lg:col-span-6 col-span-9">
              <p className="md:text-lg text-sm">
                MBOA DIGITAL est la réponse au défi d’excellence et de
                compétitivité des équipes tech en Europe et à l’international.
                ESN franco-camerounaise, nous sommes le “bras droit caché” des
                équipes tech de grands groupes en France, Belgique, Allemagne et
                Nouvelle Zélande. Avec +10 ans d’expérience dans les secteurs du
                Télécom, E-paiement, Logistique et Transport, nous répondons aux
                problématiques courantes des directions techniques.
              </p>
            </div>
          </div>
          <img
            src="/assets/images/teams.png"
            alt="antene"
            className="max-w-full w-full h-auto md:block hidden "
          />
          <img
            src="/assets/images/teams-mob.svg"
            alt="antene"
            className="max-w-full w-full h-auto md:hidden block"
          />
          <div className="grid md:grid-cols-12 md:my-16 my-12 ">
            <div className="col-span-4"></div>
            <div className="col-span-8">
              <p className="md:text-lg text-sm">
                Au cœur de nos opérations se trouvent des équipes d’experts,
                principalement issus de la France et du vaste bassin de talents
                africains. Nos talents sont continuellement formés aux nouvelles
                tendances technologiques grâce à un programme de formation et de
                mentorat internes sur mesure. Cette dévotion garantit notre
                alignement avec les exigences dynamiques et compétitives du
                marché technologique. Fondée en 2018, notre ambition est de
                créer une niche substantielle dans le paysage technologique
                mondial, en agissant comme un pont entre les talents européens
                et africains de premier plan et les principales entreprises
                technologiques mondiales, favorisant un héritage d'innovation et
                d'excellence.
              </p>
              <div className="flex justify-start items-center mt-12">
                <Button
                  description="Rencontrez l'équipe"
                  link="/about-us/teams"
                />
              </div>
            </div>
          </div>
        </div>
      </SectionContainer>
      <SectionContainer bgColor="bg-black">
        <div>
          <div style={bgStyle}></div>
          <div className="md:text-lg text-sm text-white md:py-[164px] py-16  ">
            <div className="grid md:grid-cols-12">
              <div className="col-span-7">
                <HeaderText white>
                  Une porte grande ouverte vers un nouveau vivier de talents
                </HeaderText>
              </div>
            </div>
            <div className="grid md:grid-cols-12 mt-12">
              <div className="flex flex-col gap-8 col-span-8">
                <p>
                  Dans un contexte marqué par des chantiers de transition
                  digitale majeurs, et une pénurie mondiale de travailleurs
                  qualifiée, MBOA DIGITAL a développé un modèle qui permet de
                  combiner des talents en Europe et en Afrique. Avec sa
                  population jeune, éduquée, l’Afrique est considérée comme un
                  continent d’avenir. Notre réseau, notre savoir-faire, nous
                  permettent d’ouvrir à nos clients une porte sur de nouvelles
                  ressources tout en gardant une proximité qui leur est chère.
                  Plus de projets qui trainent dans les tiroirs, faute de
                  ressources pour exécuter, nous nous inscrivons en partenaire
                  de confiance pour une transformation digitale tout en douceur.
                </p>
              </div>
            </div>
          </div>
        </div>
      </SectionContainer>
      <SectionContainer>
        <GridContainer
          title=" Le saviez-vous ?"
          className="md:pb-32 pb-16"
          secondChildren={
            <div className="grid md:gap-8 gap-4 md:text-lg text-sm">
              <p>
                L’Europe, qui compte des talents tech de qualité, fait face à
                une pénurie, comme partout dans le monde. Cette tendance va
                s’accentuer dans les prochaines années, avec des centaines de
                milliers de postes non pourvus dans le digital. Dans le même
                temps, l'Afrique se distingue comme un bassin particulièrement
                avantageux, principalement en raison de sa jeunesse. Le
                continent compte environ 1,46 milliard d'habitants avec un âge
                moyen d'environ 19,5 ans, ce qui témoigne d'un vaste réservoir
                de talents technologiques potentiels.
              </p>
              <p>
                Rechercher des modèles alternatifs; créer dans notre cas des
                liens Europe - Afrique pour accompagner davantage les
                entreprises dans leur transition numérique tout en limitant les
                écueils est aujourd’hui vital pour une entreprise du numérique
                durable.
              </p>
            </div>
          }
        />
      </SectionContainer>
    </div>
  );
};

export default React.memo(AboutUs);
