import React from "react";

const ResultsDetails = () => {
  return (
    <div className="grid gap-8 md:text-lg text-sm">
      <div>
        <h5 className="font-bold">Optimisation des Ressources</h5>
        <p>
          Réduction significative des pertes matérielles, grâce à une meilleure
          précision dans les commandes et le suivi des retours. L’économie
          annuelle est estimée à plus d’un million d’euros.
        </p>
      </div>
      <div>
        <h5 className="font-bold">Efficacité Améliorée</h5>
        <p>
          La mise en place du portail a permis de réduire de moitié le temps
          moyen d’installation de sites.
        </p>
      </div>
    </div>
  );
};

export default ResultsDetails;
