import React, { ReactNode, useEffect, useRef, useState } from "react";
import SectionContainer from "../../shared-components/SectionContainer";
import { Button } from "../../shared-components/Button";
import HeaderText from "../../shared-components/HeaderText";
import GridContainer from "../../shared-components/GridContainer";
import Accordion from "../../shared-components/Accordion";
import IndustryCarousel from "../../shared-components/IndustryCarousel";
import useMatchMedia from "../../MediaMatch";
import { motion, useAnimation } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay"; // Ensure to import autoplay styles
import Marquee from "react-fast-marquee";
import { carouselDate } from "../../data/data";

interface Partner {
  name: string;
  logo: string;
  logoHover: string;
}
interface PartnerCarouselProps {
  partners?: Partner[];
}

interface StatItemProps {
  number: string;
  description: string;
}
const variants = {
  hiddenMobile: {
    y: "-100vh", // Mobile: animate from top
    opacity: 0,
  },
  hiddenDesktop: {
    x: "-100vw", // Desktop: animate from left
    opacity: 0,
  },
  visible: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 0.8 },
  },
};
const useCounter = (number: string, duration: number): string => {
  const [count, setCount] = useState<string>(number);

  useEffect(() => {
    const numericValue = parseFloat(number);
    const isNumeric =
      !isNaN(numericValue) && isFinite(numericValue) && !number.includes("%");

    if (isNumeric) {
      let start = 0;
      const target = numericValue;
      const interval = duration / target;
      const increment = (target / duration) * 10;

      const timer = setInterval(() => {
        start += increment;
        if (start > target) {
          setCount(target.toString());
          clearInterval(timer);
        } else {
          setCount(Math.floor(start).toString());
        }
      }, interval);

      return () => clearInterval(timer);
    }
  }, [number, duration]);

  return count;
};

const Home = () => {
  const [openAccordionId, setOpenAccordionId] = useState<number | null>(null);
  const toggleAccordion = (id: number) => {
    // If the clicked accordion is already open, close it, otherwise open the clicked accordion
    setOpenAccordionId(openAccordionId === id ? null : id);
  };
  const accordionData = [
    {
      title: "Consulting",
      content: `Nous proposons des services de conseil personnalisés pour aider nos clients à optimiser leurs processus et atteindre leurs objectifs commerciaux plus efficacement. Nos offres incluent l'évaluation, la mise en œuvre et la formation, toutes adaptées aux besoins spécifiques de chaque client. Notre équipe d'experts collabore étroitement avec les clients pour garantir des transitions fluides et efficaces.

      Chez MBOA DIGITAL, l'accent est mis sur l'humain plutôt que sur la technologie. Nous priorisons l'amélioration de l'expérience utilisateur. Nos experts procèdent à des évaluations des systèmes en place, proposent et déploient des améliorations pertinentes, et assurent la formation des employés sur les nouveaux logiciels ou processus. Le succès de nos interventions est mesuré par l'adoption effective des solutions, les économies réalisées, la valeur ajoutée et l'amélioration de l'expérience utilisateur, plutôt que par la simple livraison de solutions technologiques.`,
    },
    {
      title: "Conception et prototypage",
      content: `La conception de logiciels représente un investissement substantiel et comporte son lot de risques, notamment le risque que le produit fini ne trouve pas son public, que ce soit par manque de pertinence ou parce que les besoins du marché ont évolué. Pour minimiser ces risques, il est crucial d'impliquer les utilisateurs dès les premières étapes du développement afin de recueillir leurs retours et d'ajuster rapidement la direction du projet si nécessaire. C'est dans cet esprit que nous avons développé nos services spécialisés en conception et prototypage.

      Notre équipe experte en design de produits et expérience utilisateur est équipée pour développer et tester rapidement des prototypes, permettant des itérations efficaces et la collecte de retours utilisateurs en temps réel. Nos créations ne se limitent pas à être fonctionnelles ; elles sont également esthétiques et conçues pour offrir une expérience utilisateur optimale. Le résultat de notre processus de prototypage offre un modèle concret que l'équipe de développement peut utiliser pour concrétiser le service envisagé et validé avec vous.`,
    },
    {
      title: "Web & Mobile",
      content: ` Nous sommes experts en développement d'applications web robustes et performantes, capables de transformer le niveau de performance de votre entreprise. Notre équipe, forte de nombreuses années d'expérience dans la création de systèmes complexes pour divers secteurs, est spécialisée dans l'élaboration de solutions web adaptées à des besoins très variés.
      
      Que vos utilisateurs alternent entre mobiles et ordinateurs de bureau, ils bénéficieront d'une expérience fluide grâce à nos applications web réactives. Besoin d'accéder à des informations en mode hors ligne ? Nous intégrons des fonctionnalités offline. Votre application doit-elle supporter de quelques centaines à plusieurs millions d'utilisateurs ? Nous optimisons votre infrastructure web pour une mise à l'échelle automatique, garantissant une performance inébranlable peu importe la charge. Nos solutions web assurent une utilisation efficace et continue, quel que soit le lieu ou le dispositif utilisé par vos utilisateurs.`,
    },
    {
      title: "Cloud computing",
      content: `Chez MBOA DIGITAL, notre service d'ingénierie du cloud inclut la conception, la mise en œuvre et la gestion d'infrastructures et d'applications cloud. Nous exploitons la flexibilité, l'évolutivité et la rentabilité du cloud computing pour soutenir vos objectifs commerciaux. Notre équipe, composée d'ingénieurs certifiés en cloud computing, est prête à évaluer votre infrastructure actuelle pour identifier les meilleures opportunités de migration vers le cloud. Nous créons des architectures sur mesure, utilisant des fournisseurs renommés tels qu'Amazon Web Services, Microsoft Azure et Google Cloud Platform.
      
      Une fois votre infrastructure cloud en place, nous assurons sa gestion et son maintien, incluant la surveillance des performances, la sécurité, et le support technique continu. Nous offrons aussi des formations pour aider votre équipe à maîtriser les technologies cloud et adopter les meilleures pratiques. Nos ingénieurs DevOps optimisent la gestion automatisée de votre infrastructure et applications cloud, minimisant les risques tout en maximisant les avantages du cloud computing.`,
    },
    {
      title: "Service Desk",
      content: `Notre Service Desk est à disposition de nos clients 24 heures sur 24, 7 jours sur 7. À tout moment, nos clients peuvent solliciter notre équipe d'assistance pour résoudre leurs problèmes techniques via téléphone, e-mail ou chat en ligne. Notre équipe, hautement qualifiée et expérimentée, est spécialisée dans le diagnostic et la résolution d'une large gamme de problèmes techniques.
      
      Nous offrons également un service de surveillance proactive, permettant de détecter et d'adresser les problèmes avant qu'ils ne dégradent l’expérience utilisateur. Ce service inclut des contrôles systématiques et des mises à jour régulières, accompagnés de notifications en temps réel en cas de problème. Cela garantit une performance optimale des systèmes de nos clients et réduit les interruptions, leur permettant de se concentrer pleinement sur leur cœur de métier tandis que nous veillons sur leurs infrastructures et applications.`,
    },
  ];

  return (
    <div className="w-full flex flex-col md:gap-32 gap-16">
      <HeroSection />
      <StatsSection />

      <TeamsWorkingSection />
      <SectionContainer>
        <div className="grid md:grid-cols-12 mb-12">
          <div className="md:col-span-9 lg:col-span-7  grid lg:gap-12 gap-9">
            <h5 className="text-[32px] leading-[40px] ">Nos réalisations</h5>
            <HeaderText>
              {" "}
              Des produits robustes utilisés à grande échelle
            </HeaderText>
          </div>
        </div>
        <IndustryCarousel items={carouselDate} />
      </SectionContainer>
      <InnovativeProductsSection />
      <PartnerCarousel />
      <SectionContainer>
        <GridContainer
          title="Expertises"
          className="md:pb-[172px] pb-7"
          secondChildren={
            <div className="container mx-auto md:mt-5 mt-2">
              {accordionData.map((item, index) => (
                <Accordion
                  key={index}
                  id={index}
                  title={item.title}
                  isOpen={openAccordionId === index}
                  toggleOpen={toggleAccordion}
                >
                  {item.content.split("\n").map((paragraph, idx) => (
                    <p className="my-2" key={idx}>
                      {paragraph}
                    </p>
                  ))}
                  {/* You can replace <p>{item.content}</p> with any custom component or JSX */}
                </Accordion>
              ))}
            </div>
          }
        />
      </SectionContainer>
    </div>
  );
};

const HeroSection = () => {
  const isDesktop = useMatchMedia("(min-width: 768px)");

  return (
    <motion.div
      variants={variants}
      initial={isDesktop ? "hiddenDesktop" : "hiddenMobile"}
      animate="visible"
    >
      <SectionContainer>
        <div className="grid md:grid-cols-12">
          <div className="md:col-span-7   grid md:gap-12 gap-9">
            <HeaderText>Équipes de développeurs d’élite</HeaderText>
            <p className="md:text-2xl text-sm">
              Gagnez en compétitivité en vous appuyant sur des compétences
              digitales de haut niveau. Embarquez votre équipe en
              <span className="font-semibold"> quelques jours.</span>
            </p>
            <div>
              <Button
                description="Discutons de votre projet"
                link="https://calendly.com/habib-tafo"
                target="_blank"
              />
            </div>
          </div>
        </div>
      </SectionContainer>
    </motion.div>
  );
};

const TeamsWorkingSection = () => {
  const isDesktop = useMatchMedia("(min-width: 768px)");

  return (
    <motion.div
      variants={variants}
      initial={isDesktop ? "hiddenDesktop" : "hiddenMobile"}
      animate="visible"
    >
      <SectionContainer bgColor="bg-black">
        <div className="flex flex-col  justify-center md:gap-16 gap-12 md:py-[120px] py-16 text-white text-center">
          <img
            src="/assets/images/teams-working.png"
            alt="A team working together on a project"
            className="max-w-full max-h-full mx-auto w-full md:block hidden"
          />
          <img
            src="/assets/images/teams-working-mob.svg"
            alt="A team working together on a project"
            className="max-w-full max-h-full mx-auto w-full md:hidden block"
          />
          <HeaderText white>
            Une porte grande ouverte vers un nouveau vivier de talents
          </HeaderText>
          <p className="md:text-lg text-sm">
            Dans un contexte marqué par des chantiers de transition digitale
            majeurs, et une pénurie mondiale de travailleurs qualifiés, MBOA
            DIGITAL a développé un modèle qui permet de combiner des talents en
            Europe et en Afrique. Avec sa population jeune, éduquée, l’Afrique
            est considérée comme un continent d’avenir. Notre réseau, notre
            savoir-faire, nous permettent d’ouvrir à nos clients une porte sur
            de nouvelles ressources tout en gardant une proximité qui leur est
            chère. Plus de projets qui trainent dans les tiroirs, faute de
            ressources pour exécuter, nous nous inscrivons en partenaire de
            confiance pour une transformation digitale tout en douceur.
          </p>
          <div className="flex justify-center">
            <Button
              description="En savoir plus sur nous"
              variant="white"
              link="/about-us/mission"
            />
          </div>
        </div>
      </SectionContainer>
    </motion.div>
  );
};

const StatItem: React.FC<StatItemProps> = ({ number, description }) => {
  const count = useCounter(number, 500);
  const hasDigits = /\d/.test(number) && number.includes("+");

  return (
    <div className="grid md:gap-6 gap-2 text-center md:my-8 my-4 mx-4 md:m-0 md:p-2 p-1">
      <div className="xxl:text-5xl lg:text-3xl font-[500] ">
        {count}
        {hasDigits ? "+" : null}
      </div>
      <div className="lg:text-lg text-sm">{description}</div>
    </div>
  );
};

const InnovativeProductsSection = () => {
  return (
    <SectionContainer>
      <div className="relative flex flex-col  justify-center text-center gap-12 xxl:gap-16">
        <div>
          <HeaderText>Nous concevons et construisons</HeaderText>
          <HeaderText>des produits innovants.</HeaderText>
        </div>

        <p className="text-sm md:text-lg">
          Le numérique est désormais au cœur de chaque entreprise. La capacité
          d'une entreprise à innover dans ce domaine est un véritable game
          changer. Chez MBOA DIGITAL, nous comprenons le métier et les processus
          de nos clients et nous nous engageons à fournir des solutions
          innovantes qui font la différence. Nous avons des antécédents prouvés
          de fourniture de logiciels robustes utilisés à grande échelle, et pour
          cela nous nous appuyons sur une palette technologique en constante
          évolution.
        </p>
      </div>
    </SectionContainer>
  );
};

const StatsSection = () => {
  const stats = [
    { number: "100+", description: "Projets réalisés" },
    { number: "Millions", description: "D’utilisateurs finaux satisfaits" },
    {
      number: "30 à 50% ",
      description: "D’économie ",
    },
    { number: "Millions €", description: "De retour sur Investissement" },

    { number: "Certified", description: "Clouds AWS & Azure" },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % stats.length);
    }, 2000); // 500 seconds expressed in milliseconds

    return () => clearInterval(interval);
  }, [stats.length]);

  return (
    <SectionContainer>
      <div className="grid md:grid-cols-5 gap-3 md:gap-5 relative ">
        <div
          className={`absolute bg-neutral-95 h-full rounded-3xl transition-all z-10 duration-500  ${
            activeIndex !== -1 ? "animate-slideRight" : ""
          }`}
          style={
            isMobile
              ? {
                  height: `calc(100% / ${stats.length})`,
                  top: `calc(${(activeIndex * 100) / stats.length}%)`,
                  width: "100%",
                }
              : {
                  width: `calc(100% / 5)`,
                  left: `calc(${(activeIndex * 100) / 5}%)`,
                }
          }
        ></div>
        {stats.map((stat, index) => (
          <div
            key={index}
            className=" z-20"
            onMouseEnter={() => setActiveIndex(index)}
          >
            <StatItem number={stat.number} description={stat.description} />
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};
const PartnerCarousel: React.FC<PartnerCarouselProps> = ({}) => {
  const partners: Partner[] = [
    {
      name: "Nest JS",
      logo: "/assets/images/carousel/img1.svg",
      logoHover: "/assets/images/carousel/img-color-1.svg",
    },
    {
      name: "JavaScript",
      logo: "/assets/images/carousel/img2.svg",
      logoHover: "/assets/images/carousel/img-color-2.svg",
    },
    {
      name: "Amazon Cloud",
      logo: "/assets/images/carousel/img3.svg",
      logoHover: "/assets/images/carousel/img-color-3.svg",
    },
    {
      name: "React",
      logo: "/assets/images/carousel/img4.svg",
      logoHover: "/assets/images/carousel/img-color-4.svg",
    },
    {
      name: "Apple",
      logo: "/assets/images/carousel/img5.svg",
      logoHover: "/assets/images/carousel/img-color-5.svg",
    },
    {
      name: "Python",
      logo: "/assets/images/carousel/img6.svg",
      logoHover: "/assets/images/carousel/img-color-6.svg",
    },
    {
      name: "Android",
      logo: "/assets/images/carousel/img7.svg",
      logoHover: "/assets/images/carousel/img-color-7.svg",
    },
    {
      name: "Azure Cloud",
      logo: "/assets/images/carousel/img8.svg",
      logoHover: "/assets/images/carousel/img-color-8.svg",
    },
    {
      name: "TypsScript",
      logo: "/assets/images/carousel/img9.svg",
      logoHover: "/assets/images/carousel/img-color-9.svg",
    },
    {
      name: "Angular",
      logo: "/assets/images/carousel/img10.svg",
      logoHover: "/assets/images/carousel/img-color-10.svg",
    },
  ];
  const marqueeRef = useRef<HTMLDivElement>(null);
  const [spacing, setSpacing] = useState(0);

  const updateSpacing = () => {
    if (marqueeRef.current && partners.length > 0) {
      // Get the total width of the marquee container
      const marqueeWidth = marqueeRef.current.offsetWidth;

      // Calculate the total width of all images combined
      const totalImagesWidth = partners.reduce((acc, partner) => {
        // Assuming each image approximately takes 56px width (h-14 class), adjust if different
        return acc + 56; // Update this value based on your actual image sizes
      }, 0);
      // Calculate the remaining space to distribute
      const remainingSpace = marqueeWidth - totalImagesWidth;
      // Calculate the spacing, subtract 1 to avoid counting an extra space at the end
      const newSpacing = remainingSpace / (partners.length - 1);
      if (marqueeWidth < 768) {
        setSpacing(24);
      } else if (marqueeWidth < 992) {
        setSpacing(40);
      } else {
        setSpacing(newSpacing);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateSpacing);
    updateSpacing(); // Initial setup

    return () => {
      window.removeEventListener("resize", updateSpacing);
    };
  }, [partners]);

  return (
    <div ref={marqueeRef} className="w-full flex h-[170px] overflow-hidden">
      <Marquee pauseOnHover={true} gradient={false} speed={60}>
        {partners.map((partner, index) => (
          <ImageComponent key={index} partner={partner} spacing={spacing} />
        ))}
      </Marquee>
    </div>
  );
};
interface ImageComponentProps {
  partner: Partner;
  spacing: number;
}
const ImageComponent: React.FC<ImageComponentProps> = ({
  partner,
  spacing,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className="relative inline-block"
      style={{
        marginRight: `${Math.floor(spacing / 2)}px`,
        marginLeft: `${Math.floor(spacing / 2)}px`,
      }}
    >
      <Tooltip content={partner.name}>
        <img
          src={hover ? partner.logoHover : partner.logo}
          alt={partner.name}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className="h-14 transition-opacity duration-300 ease-in-out"
        />
      </Tooltip>
    </div>
  );
};

interface TooltipProps {
  content: string; // Text for the tooltip
  children: ReactNode; // The React node that the tooltip is attached to
}

const Tooltip: React.FC<TooltipProps> = ({ children, content }) => {
  const [show, setShow] = useState(false);
  const node = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    setShow(true);
  };

  const handleMouseLeave = () => {
    setShow(false);
  };

  return (
    <div
      className="relative flex flex-col items-center"
      ref={node}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Target element that tooltip is attached to */}
      {children}

      {true && (
        <div
          className="absolute -top-12 mb-2 px-3 py-1.5 text-sm bg-black text-white rounded-full shadow-lg whitespace-nowrap"
          style={{
            transition: "opacity 0.3s ease-in-out",
            opacity: show ? 1 : 0,
          }}
        >
          {content}
          <div className="caret"></div>
        </div>
      )}
    </div>
  );
};

// Extracted Header Text Component for Reusability

export default React.memo(Home);
