/* eslint-disable react/prop-types */
import React from "react";

interface IconProps {
  IconClass?: string;
}

export const ArrowBackIcon: React.FC<IconProps> = ({ IconClass }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M10.7327 19.7907C11.0326 20.0764 11.5074 20.0649 11.7931 19.7649C12.0787 19.465 12.0672 18.9903 11.7673 18.7046L5.51587 12.75L20.25 12.75C20.6642 12.75 21 12.4142 21 12C21 11.5858 20.6642 11.25 20.25 11.25L5.51577 11.25L11.7673 5.29526C12.0672 5.00958 12.0787 4.53484 11.7931 4.23492C11.5074 3.93499 11.0326 3.92345 10.7327 4.20913L3.31379 11.2758C3.14486 11.4367 3.04491 11.642 3.01393 11.8554C3.00479 11.9022 3 11.9505 3 12C3 12.0495 3.00481 12.098 3.01398 12.1449C3.04502 12.3581 3.14496 12.5632 3.31379 12.724L10.7327 19.7907Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowBackIcon;
