import React from "react";

const SiteDetails = () => {
  return (
    <div className="flex flex-col gap-8 md:py-32 py-16 ">
      <div className="flex justify-start gap-4 items-center">
        <h3 className="md:text-[40px] md:leading-[50px] text-lg font-bold">
          Installation sur site XYZ
        </h3>
        <div className="text-sm font-bold text-white  h-[30px] bg-green-60  p-2.5 rounded-lg flex justify-end items-center ">
          Initialisé
        </div>
      </div>

      <div className="flex gap-2">
        Programme: <span className="font-bold">NEW DEAL</span>
      </div>

      <div className="md:grid md:grid-cols-12 hidden ">
        <div className="col-span-3 flex flex-col gap-1 md:text-lg text-sm">
          <div className="font-bold">Site A (Site Client)</div>
          <div>
            <div>
              N° du site: <span className="font-bold">123</span>
            </div>
            <div>
              N° d'opération: <span className="font-bold">2 rue du Gard,</span>
            </div>
            <div>
              Adresse: <span className="font-bold">NEW DEAL</span>
            </div>
            <div className="font-bold">67100 Strasbourg</div>
          </div>
        </div>
        <img
          src="/assets/images/satelite.png"
          alt="satelite"
          className="max-w-full col-span-6 h-auto"
        />
        <div className="col-span-3 flex flex-col gap-1 text-lg pl-16">
          <div className="font-bold">Site B</div>
          <div>
            <div>
              N° du site: <span className="font-bold">456</span>
            </div>
            <div>
              N° d'opération: <span className="font-bold">XYZ</span>
            </div>
            <div>
              Adresse: <span className="font-bold">5 rue du Chevreuil</span>
            </div>
            <div className="font-bold">67100 Strasbourg</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-9  md:hidden">
        <img
          src="/assets/images/satelite.png"
          alt="satelite"
          className="max-w-full col-span-6 h-auto"
        />
        <div className="flex justify-between text-xs">
          <div className=" grid gap-1 ">
            <div></div>
            <div className="font-bold">Site A (Site Client)</div>
            <div>
              <div>
                N° du site: <span className="font-bold">123</span>
              </div>
              <div>
                N° d'opération:{" "}
                <span className="font-bold">2 rue du Gard,</span>
              </div>
              <div>
                Adresse: <span className="font-bold">NEW DEAL</span>
              </div>
              <div className="font-bold">67100 Strasbourg</div>
            </div>
          </div>
          <div className="grid gap-1 ">
            <div className="font-bold">Site B</div>
            <div>
              <div>
                N° du site: <span className="font-bold">456</span>
              </div>
              <div>
                N° d'opération: <span className="font-bold">XYZ</span>
              </div>
              <div>
                Adresse: <span className="font-bold">5 rue du Chevreuil</span>
              </div>
              <div className="font-bold">67100 Strasbourg</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteDetails;
