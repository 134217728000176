import React, { useEffect, useState } from "react";
import SectionContainer from "../../../shared-components/SectionContainer";
import HeaderText from "../../../shared-components/HeaderText";
import SiteDetails from "./components/SiteDetails";
import ApproachDetails from "./components/ApproachDetails";
import SolutionDetails from "./components/SolutionDetails";
import ResultsDetails from "./components/ResultsDetails";
import ConclusionDetails from "./components/ConclusionDetails";
import GridContainer from "../../../shared-components/GridContainer";
import { Badge } from "../../../shared-components/Badge";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import { Button } from "../../../shared-components/Button";
import ArrowBackIcon from "../../../shared-components/icon/ArrowBack";
import { carouselDate } from "../../../data/data";
import IndustryCarousel from "../../../shared-components/IndustryCarousel";

const Telecommunication = () => {
  let index = 1; // Initialize index for accessing ZindexTable

  let cards = [
    {
      key: 1,
      content: (
        <img
          src="/assets/images/telecom-img1.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 2,
      content: (
        <img
          src="/assets/images/telecom-img2.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 3,
      content: (
        <img
          src="/assets/images/telecom-img3.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 4,
      content: (
        <img
          src="/assets/images/telecom-img1.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 5,
      content: (
        <img
          src="/assets/images/telecom-img2.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 6,
      content: (
        <img
          src="/assets/images/telecom-img3.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
  ];

  const [goToSlide, setGoToSlide] = useState(0);
  const slides = cards.map((card, index) => {
    return {
      key: card.key,
      content: (
        <div
          style={{
            transform: goToSlide === index ? "scale(1.5)" : "scale(1)",
            transition: "transform 0.5s ease",
          }}
        >
          {card.content}
        </div>
      ),
      onClick: () => setGoToSlide(index),
      config: {
        scale: goToSlide === index ? 1.6 : 1,
      },
    };
  });

  useEffect(() => {
    // Set up an interval to update the z-index periodically
    const interval1 = setInterval(() => {
      setGoToSlide(index);
      index = (index + 1) % cards.length;
    }, 1875); // Interval set to 3 seconds

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(interval1);
  }, []);
  return (
    <div className="w-full flex flex-col md:gap-32 gap-16 md:pb-32 pb-16 ">
      <SectionContainer>
        <div className="grid md:grid-cols-12">
          <div className="flex flex-col gap-8 col-span-8">
            <div>
              <Button link="/case-studies" icon={<ArrowBackIcon />} />
            </div>

            <div>
              <Badge description="Telecommunications" />
            </div>

            <HeaderText>
              Plateforme Digitale : Une réponse agile aux défis de la
              télécommunication
            </HeaderText>
          </div>
        </div>
      </SectionContainer>
      <SectionContainer>
        <div className="grid grid-cols-12 ">
          <div className="md:col-span-4"></div>
          <div className="md:col-span-8 col-span-12">
            <p className="md:text-lg text-sm">
              Dans l'arène complexe des télécommunications, un des principaux
              opérateurs français se trouvait confronté à un défi colossal : la
              gestion mal maîtrisée de son réseau de sous-traitants responsable
              du déploiement et la maintenance de ses sites. Ces difficultés
              organisationnelles entravaient l'efficacité et la rentabilité. Il
              y avait un manque criant de visibilité et de suivi des
              équipements, et pour des pièces de cette valeur les pertes se
              chiffraient en millions d’euros.
            </p>
          </div>
        </div>
      </SectionContainer>
      <SectionContainer>
        <img
          src="/assets/images/antene-full.png"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      </SectionContainer>
      <SectionContainer>
        <GridContainer title="Approche" secondChildren={<ApproachDetails />} />
      </SectionContainer>

      <SectionContainer bgColor="bg-neutral-95">
        <SiteDetails />
      </SectionContainer>

      <SectionContainer>
        <GridContainer title="Solution" secondChildren={<SolutionDetails />} />
      </SectionContainer>
      <SectionContainer>
        <div className="h-[400px] md:block hidden ">
          <Carousel
            slides={slides}
            goToSlide={goToSlide}
            offsetRadius={2}
            showNavigation={false}
            animationConfig={config.gentle}
          />
        </div>

        <div className="flex flex-col md:gap-12 gap-4 md:hidden">
          <img
            src="/assets/images/telecom-img1.svg"
            alt="antene"
            className="max-w-full w-full h-auto "
          />
          <img
            src="/assets/images/telecom-img2.svg"
            alt="antene"
            className="max-w-full w-full h-auto "
          />
          <img
            src="/assets/images/telecom-img3.svg"
            alt="antene"
            className="max-w-full w-full h-auto "
          />
        </div>
      </SectionContainer>

      <SectionContainer>
        <GridContainer title="Résultats" secondChildren={<ResultsDetails />} />
      </SectionContainer>
      <SectionContainer>
        <GridContainer
          title="Conclusion"
          secondChildren={<ConclusionDetails />}
        />
      </SectionContainer>

      <SectionContainer>
        <div className="grid md:grid-cols-12 mb-12">
          <div className="md:col-span-7  grid md:gap-12 gap-9">
            <h5 className="text-[32px] leading-[40px] ">Travaux connexes</h5>
          </div>
        </div>
        <IndustryCarousel items={carouselDate} />
      </SectionContainer>
    </div>
  );
};

export default React.memo(Telecommunication);
