export const carouselDate: {
  image: string;
  title: string;
  badge: string;
  link: string;
  linkCase: string;
  description: string;
  imageUrl: string;
}[] = [
  {
    badge: "Telecommunications",
    image: "/assets/images/carousel2.svg",
    imageUrl: "/assets/images/case-logistic.svg",
    title:
      "Plateforme Digitale : Une Réponse Agile aux Défis de la Télécommunication",
    link: "case-studies/telecomunication",
    linkCase: "telecomunication",
    description: "",
  },

  {
    badge: "Logistique",
    image: "/assets/images/carousel2.png",
    title:
      "Réinvention Digitale : Un Nouvel Horizon pour la Livraison de Courrier en Océanie",
    link: "/case-studies/lco",
    linkCase: "lco",
    description: "",
    imageUrl: "/assets/images/case-logistic.svg",
  },
  {
    badge: "Electronique",
    image: "/assets/images/carousel4.svg",
    title:
      "Révolution dans la Gestion des Retours : Un Système de RMA Innovant pour un Géant des Télécoms",
    link: "/case-studies/electronic-rma",
    linkCase: "electronic-rma",
    description: "",
    imageUrl: "/assets/images/case-logistic.svg",
  },
  // {
  //   badge: "Electronique",
  //   image: "/assets/images/carousel5.svg",
  //   title:
  //     "Optimisation de la Logistique Avale : Un Système Sur Mesure pour un Leader des Terminaux de Paiement",
  //   link: "/case-studies/electronic-ola",
  //   linkCase: "electronic-ola",
  //   description: "",
  //   imageUrl: "/assets/images/case-logistic.svg",
  // },
  {
    badge: "Santé",
    image: "/assets/images/carousel6.svg",
    title: "Révolution Numérique dans le Secteur de la Santé Pédiatrique ",
    link: "/case-studies/digipediaMed",
    linkCase: "digipediaMed",
    description: "",
    imageUrl: "/assets/images/case-logistic.svg",
  },
  {
    badge: "Nos produits",
    image: "/assets/images/carousel7.svg",
    imageUrl: "/assets/images/case-logistic.svg",
    title: "L'Automatisation au Service du Contrôle de Gestion",
    link: "case-studies/autoGestion",
    linkCase: "autoGestion",
    description: "",
  },
  {
    badge: "Nos produits",
    image: "/assets/images/carousel1.svg",
    imageUrl: "/assets/images/case-logistic.svg",
    title: "La Révolution de la Gestion de Paie au Cameroun",
    link: "case-studies/payrollr",
    linkCase: "payrollr",
    description: "",
  },
];
