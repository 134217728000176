import React from "react";
import { useField } from "formik";

interface InputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label: string;
  name: string;
  as?: "input" | "textarea"; // Allows the component to be used as an input or textarea
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  as = "input",
  ...props
}) => {
  const [field, meta] = useField(props.name);

  // Dynamically choose the element type, defaulting to input
  const Component = as;

  return (
    <div className={`relative md:text-lg text-sm ${props.className || ""}`}>
      <Component
        {...field}
        {...props}
        className={`peer bg-transparent border-b border-black text-black focus:outline-none
         focus:border-black placeholder-transparent w-full ${
           props.type === "textarea" ? "h-[500px] text-wrap" : "h-[56px]"
         } ${meta.touched && meta.error ? "border-red-500" : ""}`}
        aria-invalid={meta.error && meta.touched ? "true" : "false"}
        aria-describedby={`${props.id || props.name}-error`}
      />
      <label
        htmlFor={props.id || props.name}
        className={`absolute left-0 transition-all text-black peer-focus:-top-3.5 peer-focus:text-black ${
          field.value ? "-top-3.5 text-black" : "top-4 text-base"
        }`}
      >
        {label}
      </label>
      {meta.touched && meta.error && (
        <div
          id={`${props.id || props.name}-error`}
          className="text-red-500 text-xs"
        >
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default InputField;
