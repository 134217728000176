import React from "react";

const ApproachDetails = () => {
  return (
    <div className="grid gap-8 md:text-lg text-sm">
      <p>
        MBOA DIGITAL est intervenu en amont, sur l’analyse des processus et le
        conseil. Le diagnostic a permis de mettre en avant deux principaux défis
        à relever :
      </p>
      <div>
        <h5 className="font-bold">Manque de Centralisation</h5>
        <p>
          Plusieurs sites à installer, plusieurs installateurs, dans plusieurs
          régions différentes. Les solutions ad hoc locales donnaient des
          performances disparates, et posaient des difficultés pour le pilotage
          de programmes à l’échelle nationale.
        </p>
      </div>
      <div>
        <h5 className="font-bold">Pertes Financières</h5>
        <p>
          Des pièces commandées en double par l’installateur, du matériel
          défectueux non retourné. Tels étaient les principales problématiques
          qui donnaient lieu à des pertes substantielles.
        </p>
      </div>
    </div>
  );
};

export default ApproachDetails;
