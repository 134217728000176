import React from "react";

const SolutionDetails = () => {
  return (
    <div className="grid gap-8 md:text-lg text-sm">
      <p>
        Face à ces difficultés, nous avons réalisé un portail web regroupant
        toutes les parties prenantes : installateurs, transporteurs,
        préparateurs de commande, chefs de projets… Le portail fusionne une
        expérience utilisateur intuitive et intègre de façon fluide les systèmes
        ERP existants. Cela permet une synchronisation automatique et une
        gestion optimisée des flux de données. Cette approche a transformé
        radicalement la manière dont notre client et ses sous-traitants abordent
        le déploiement et la maintenance des sites de télécommunication.
      </p>
      <div>
        <h5 className="font-bold">Centralisation de l’information</h5>
        <p>
          L’information est centralisée dans le portail : elle y est soit
          générée, soit récupérée à la source et intégrée au portail en temps
          réel. Que ce soit lorsque l’installateur fait une demande
          d’équipement, quand le transporteur déclare un équipement livré… Les
          parties concernées ont accès à l’information en temps réel. Les
          tableaux de bord donnent une visibilité totale sur l'évolution des
          projets, permettant une réaction agile face aux imprévus et assurant
          le respect des délais. Des rapports détaillés pour un contrôle
          renforcé, garantissent quant à eux le respect des engagements niveau
          de service des sous-traitants.
        </p>
      </div>
      <div>
        <h5 className="font-bold">Automatisation et Configuration</h5>
        <p>
          La principale innovation du portail est de transférer la configuration
          des sites des installateurs vers des règles d’ingénierie intégrées. Le
          portail calcule automatiquement les équipements nécessaires aux
          installations, selon la configuration des sites à installer. Ceci
          élimine les erreurs humaines et optimise les ressources.
        </p>
      </div>
    </div>
  );
};

export default SolutionDetails;
